import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles, Item } from './index';
import { formatShortDate } from '../../utils/date';
import {
  checkIfApplicationIsNonIndividual,
  currencyConversion,
  isFieldApplicable,
  isFieldPercent,
  isGlobalFundI,
  isMSARFundType,
} from '../../utils/utilityFunctions';
import { SubHeading } from '../investors/components';
import styled from '@emotion/styled';

const displayZero = (amount: number | string): number | string => {
  return amount === 0 ? '0' : amount;
};

const Li = ({
  children,
  style,
}: {
  children: JSX.Element;
  style?: any;
  component?: React.ElementType;
}) => {
  return (
    <Typography
      sx={{
        textAlign: 'justify',
        //     ...styles.content,
        //     ...style,
      }}
      component={'li'}>
      {children}
    </Typography>
  );
};
const Ol = ({
  children,
  style,
  type,
}: {
  children: JSX.Element;
  style?: any;
  type?: '1' | 'a' | 'i' | 'A' | 'I';
}) => {
  return (
    <Typography component={'ol'} style={style} type={type}>
      {children}
    </Typography>
  );
};

export const InvestorGrievances = (): JSX.Element => {
  return (
    <Grid sx={{ width: '100%' }}>
      <SubHeading sx={{ backgroundColor: 'rgba(211, 47, 47, 0.1)', color: 'rgb(211, 47, 47)' }}>
        Investor Grievances
      </SubHeading>
      <Ol>
        <>
          <Li>
            <Typography>
              Investor can lodge their complaints/query/concern/grievance via email on{' '}
              <Typography sx={{ fontWeight: 500 }} component={'span'}>
                gift.fund@alt-alpha.com
              </Typography>
            </Typography>
          </Li>
          <Li>
            <Typography>
              Any dispute unresolved, may be submitted to arbitration and dealt with in accordance
              with the terms of the Contribution Agreement.
            </Typography>
          </Li>
        </>
      </Ol>
    </Grid>
  );
};

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  const { scheme } = application || {};
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Group Investment'}
                  description={application.groupInvestment ? 'Yes' : 'No'}
                />
                {application.groupInvestment && (
                  <KeyValue title={'Group Name'} description={application.groupName} />
                )}
                <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} />
                <KeyValue title={'Class Plan'} description={application.plan?.planDescription} />
                <KeyValue
                  title={'Commitment Amount'}
                  description={displayZero(application.commitmentAmount as number)}
                  amount={currencyConversion(
                    application.commitmentAmount,
                    application.plan?.currency
                  )}
                />
                <KeyValue
                  title={'Management Fees Rate'}
                  description={displayZero(application.managementFeesRate as number)}
                />
                {isFieldApplicable(application.isoperatingExpensesApplicable || '') &&
                  (isFieldPercent(
                    application.operatingExpensesType || '',
                    application.isoperatingExpensesApplicable || ''
                  ) ? (
                    <KeyValue
                      title={'Operating Expenses %'}
                      description={displayZero(application.operatingExpenses as number)}
                    />
                  ) : (
                    <KeyValue
                      title={'Operating Expenses'}
                      description={application.operatingExpensesInWords}
                    />
                  ))}
                {isMSARFundType(scheme?.fundType || '') && (
                  <>
                    <KeyValue
                      title={'Performance Fee %'}
                      description={displayZero(application.performanceFee as number)}
                    />
                    <KeyValue title={'Placement fee %'} description={application.placementFee} />
                    <KeyValue
                      title={'Exit Load %'}
                      description={displayZero(application.exitLoad as number)}
                    />
                    <KeyValue
                      title={'Exit Load Applicable Till'}
                      description={application.exitLoadApplicableTill}
                    />
                    <KeyValue title={'Hurdle Rate %'} description={application.hurdleRate} />
                  </>
                )}
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                {/* <KeyValue
                  title={'Contribution Amount'}
                  description={application.contributionChequeAmount}
                  amount={currencyConversion(application.contributionChequeAmount,application.plan?.currency)}
                /> */}
                {!isMSARFundType(scheme?.fundType || '') &&
                  (isFieldPercent(application.setupFeeType || '', 'yes') ? (
                    <>
                      <KeyValue
                        title={'Setup Fee Percentage'}
                        description={displayZero(application.setupFeePercentage as number)}
                      />
                      <KeyValue
                        title={'Setup Fee Amount'}
                        description={displayZero(application.setupFeeAmount as number)}
                        amount={currencyConversion(
                          application.setupFeeAmount,
                          application.plan?.currency
                        )}
                      />
                      <KeyValue
                        title={'GST (GST On Setup Fee Amount)'}
                        description={displayZero(application.setupFeeGST as number)}
                        amount={currencyConversion(
                          application.setupFeeGST,
                          application.plan?.currency
                        )}
                      />
                      <KeyValue
                        title={'Total Setup Fee'}
                        description={displayZero(application.totalSetupFee as number)}
                        amount={currencyConversion(
                          application.totalSetupFee,
                          application.plan?.currency
                        )}
                      />
                      <KeyValue
                        title={'TDS'}
                        description={displayZero(application.setupFeeTDS as number)}
                        amount={currencyConversion(
                          application.setupFeeTDS,
                          application.plan?.currency
                        )}
                      />
                      <KeyValue
                        title={'Setup Fee Payable'}
                        description={displayZero(application.netSetupFeeLessTDS as number)}
                        amount={currencyConversion(
                          application.netSetupFeeLessTDS,
                          application.plan?.currency
                        )}
                      />
                    </>
                  ) : (
                    <KeyValue title={'Setup Fee'} description={application.setupFeeInWords} />
                  ))}
                <KeyValue
                  title={'Total Amount Payable'}
                  description={displayZero(application.totalAmountPayable as number)}
                  amount={currencyConversion(
                    application.totalAmountPayable,
                    application.plan?.currency
                  )}
                />
                {isMSARFundType(scheme?.fundType || '') && (
                  <>
                    <KeyValue
                      title={'Drawdown Date'}
                      description={formatShortDate(application.drawDownDate)}
                    />
                    {checkIfApplicationIsNonIndividual((application || {}) as ApplicationProps) && (
                      <KeyValue
                        title={'Date of Board Meeting'}
                        description={formatShortDate(application.boardMeetingDate)}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Gridstyles>
            {isGlobalFundI(application?.schemeId || '') && <InvestorGrievances />}
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
}
