import { Route, Router } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Hidden,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { DataTable } from '../DataTable';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { fundAccess, getFundsList, updateFund } from '../../redux-store/actions/funds';
import { accessType, FundProps, GetFundsListResponseBody } from '../../redux-store/types/funds';
import { AccessHeader } from '../userManagement/relationShipManagers';
import { ConfirmationDialog } from '../commonComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

export const AccessHeaderForFund = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            color: 'primary.main',
            fontWeight: 500,
          }}
          component="span">
          Access
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            onClick={handleOpen}
            sx={{ position: 'relative', top: 4 }}
          />
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 12,
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Enable/Disable
        </Typography>
      </Box>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 550,
            p: { xs: 2, sm: '15px 20px 45px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
          <InfoOutlinedIcon color="info" fontSize="large" />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          {/* <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>
            Are you sure you want to disable this fund ?
          </Typography> */}
          <Typography sx={{ color: 'secondary.main', lineHeight: '35px', mt: 2 }}>
            Please ensure that you have at least one active fund before disabling the fund.
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default function FundsList(): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fundData, setFundData] = useState<FundProps[]>();
  const [access, setAccess] = useState(false);
  const [openDialog, setDialog] = useState<{ FundData: FundProps } | null>(null);
  const history = useHistory();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getFundsList())) as unknown as GetFundsListResponseBody;
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (!isComponentActive) {
          return;
        }
        const { funds } = res;
        setAccess(fundSetupAccess);
        setFundData(funds);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const AccessViewOrEditCell = [
    {
      header: AccessHeaderForFund,
      renderCell: (row: FundProps): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ FundData: row })}
                disabled={!access}
              />
            </Stack>
          </>
        );
      },
    },
    {
      header: 'View Plans',
      renderCell: (row: FundProps) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{ pathname: 'view-plans', state: { fundId: row.id, fundType: row.fundType } }}>
            {`${row.plans.length} ${row.plans.length <= 1 ? 'Plan' : 'Plans'}`}
          </Typography>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: FundProps): JSX.Element => {
    //     return (
    //       <>
    //         <Typography
    //           variant="h5"
    //           component={RouterLink}
    //           to={{ pathname: 'fund-details', state: { fundId: row.id } }}
    //           sx={{
    //             color: '#61D1D6',
    //             textDecoration: 'none',
    //             fontWeight: 500,
    //             fontSize: '14px',
    //             ':hover': {
    //               color: 'primary.main',
    //             },
    //           }}>
    //           View Details
    //         </Typography>
    //       </>
    //     );
    //   },
    // },
  ];

  const columns: any = [
    {
      header: 'Scheme Name',
      renderCell: (row: FundProps) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{ pathname: 'fund-details', state: { fundId: row.id } }}>
            {row.schemeName || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: 'AIF/AMC Code',
      label: 'amcCode',
      valueGetter: (row: FundProps) => row.amcCode || 'N/A',
    },
    {
      header: 'Scheme Code',
      label: 'schemeCode',
      valueGetter: (row: FundProps) => row.schemeCode || 'N/A',
    },

    {
      header: 'Investment Manager Name',

      valueGetter: (row: any) => row?.plans[0]?.fundManager?.name || 'N/A',
    },
    {
      header: 'Trustee Name',
      valueGetter: (row: any) => row?.plans[0]?.trustee?.name || 'N/A',
    },
  ];

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>Funds</Typography>
          {access && (
            <Hidden only="xs">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mr: 2.5,
                  py: 0.5,
                  px: 3,
                }}
                onClick={() => history.push('add-fund')}>
                Add Funds
              </Button>
            </Hidden>
          )}
          {access && (
            <Hidden smUp={true}>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 13,
                  mr: 2.5,
                  py: 0.5,
                  px: 3,
                  whiteSpace: 'nowrap',
                }}
                onClick={() => history.push('add-fund')}>
                Add Funds
              </Button>
            </Hidden>
          )}
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={fundData || []}
              tableHeader={[...columns, ...AccessViewOrEditCell]}
              currentPage={currentPage}
              noOfPages={1}
              isLoading={isLoading}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [columns, isLoading]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.FundData.isActive ? 'disable' : 'enable'
        } the this Fund ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedRes = (await dispatch(
                updateFund(Number(openDialog.FundData.id), {
                  ...openDialog.FundData,
                  isActive: !openDialog?.FundData.isActive,
                })
              )) as unknown as FundProps;
              setFundData(
                fundData?.map((fund) => {
                  if (Number(fund.id) === Number(updatedRes.id)) {
                    return {
                      ...fund,
                      ...updatedRes,
                    };
                  }
                  return fund;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
