import { Box, Button, CardMedia, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Item, ItemDetails } from './index';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { getDocumentTitle } from '../investors/PhysicalSigningDocuments';

export default function PhysicalSigningDocuments({
  application,
  inDialog = false,
}: {
  application: ApplicationProps;
  inDialog?: boolean;
}): JSX.Element {
  const { signedDocuments } = application;
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Signed Document Details
            </Typography>
            <React.Fragment>
              {signedDocuments && signedDocuments.length === 0 && (
                <Grid
                  xs={12}
                  sm={12}
                  sx={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: 'common.black',
                    textAlign: 'center',
                    my: 3,
                  }}>
                  No Documents
                </Grid>
              )}
              <Grid
                container
                pt={signedDocuments && signedDocuments.length && 2}
                pb={signedDocuments && signedDocuments.length && 5}
                columnSpacing={13}
                sx={{ pl: !inDialog ? { xs: 0, sm: 7, md: 10, lg: 13 } : 0 }}>
                {signedDocuments &&
                  signedDocuments?.map((document, index: number) => {
                    return (
                      <React.Fragment key={document.id}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={document.file ? 6 : 12}
                          sx={{ mt: index !== 0 ? 6 : 0 }}>
                          <Item>{getDocumentTitle[document.documentType]}</Item>
                          <ItemDetails>
                            {document.documentName?.toLowerCase() ===
                            getDocumentTitle[document.documentType]?.toLowerCase()
                              ? document.file?.name
                              : document.documentName}
                          </ItemDetails>
                        </Grid>
                        {document.file && (
                          <Grid item xs={12} sm={6}>
                            <Box
                              sx={{
                                display: 'flex',
                                mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                flexDirection: { sm: 'column', xs: 'row', md: 'row' },
                              }}>
                              <Button
                                variant="contained"
                                sx={{
                                  color: 'common.white',
                                  // minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                  minWidth: '70%',
                                  // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                  fontWeight: 600,
                                  lineHeight: 1.5,
                                }}
                                onClick={() => window.open(document.file?.viewUrl)}>
                                View
                              </Button>
                              <Tooltip title={'Download'}>
                                <IconButton
                                  sx={{
                                    // p: 0,
                                    // pl: 1.5,
                                    ':hover': {
                                      bgcolor: '#F4FCFC',
                                    },
                                    cursor: 'pointer',
                                    pointerEvents: 'all',
                                    // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                  }}
                                  onClick={() => window.open(document.file?.url)}>
                                  <CardMedia
                                    component="img"
                                    src={'/images/download-icon.png'}
                                    alt="Download"
                                    sx={{ width: '25px' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </React.Fragment>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
