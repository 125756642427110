import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM/Distributor Details
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Distributor ARN Code'}
                  description={application?.distributor?.arnCode}
                />
                <KeyValue title={'Distributor Name'} description={application?.distributor?.name} />
                <KeyValue
                  title={'Distributor RM Email Id'}
                  description={
                    application?.subDistributor?.user?.email ||
                    application?.distributor?.user?.email
                  }
                />
                <KeyValue title={'AMCs RM Email Id'} description={application?.rm?.user?.email} />
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
