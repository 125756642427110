import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { addPlan, fundAccess, getPlanDetail, updatePlan } from '../../redux-store/actions/funds';
import { accessType, addPlanType, ClassPlanProps } from '../../redux-store/types/funds';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { DistributorListType, FundManager, Trustee } from '../../redux-store/types/api-types';
import { addPlanValidation } from '../../utils/schema';
import {
  allowOnlyNumbers,
  currencyConversion,
  isFieldApplicable,
  isFieldPercent,
  isMSARFundType,
  makeStringInSingleLine,
} from '../../utils/utilityFunctions';
import { CurrencyMaster, FEE_TYPE_MASTER, YES_NO_MASTER } from '../../utils/constant';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import MFCheckbox from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';

type NewPlanType = addPlanType & {
  isApplicableForDistributor: boolean;
};
const initialValues: NewPlanType = {
  // id: '',
  schemeId: 0,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: 'inr',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  faceValue: 0,
  fundAddress1: '',
  fundManagerId: null,
  trusteeId: null,
  fundAddress2: '',
  fundAddress3: '',
  inActive: true,
  isActive: true,
  custodian: '',
  setupFee: 0,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  managementFee: 0,
  performanceFee: 0,
  placementFee: '',
  exitLoad: 0,
  operatingExpenses: 0,
  exitLoadApplicableTill: '',
  hurdleRate: 0,
  tcLink: '',
  distributorIds: null,
  isApplicableForDirect: false,
  isApplicableForDistributor: false,
  isoperatingExpensesApplicable: '',
  operatingExpensesType: '',
  operatingExpensesInWords: '',
  setupFeeType: '',
  setupFeeInWords: '',
  isHurdleRateTextApplicable: '',
  hurdleRateText: '',
  isAdditionalReturnTextApplicable: '',
  additionalReturnText: '',
};

export default function AddPlan({
  location,
}: {
  location: Location<{ fundId: string; PlanId: string; fundType: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [planInitial, setPlanInitial] = useState(initialValues);
  const [access, setAccess] = useState(false);
  const { fundId, PlanId, fundType } = location.state || {};
  const [trustee, setTrustee] = useState<any[]>([]);
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(getTrustessList())) as unknown as Trustee[];
        const fundManagerList = (await dispatch(getFundManagersList())) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        const distributorLists = (await dispatch(
          getDistributorsListForFilters()
        )) as unknown as DistributorListType[];
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList);
        setTrustee(trusteeList);
        setDistributorList(distributorLists);
        if (PlanId) {
          const GetFundDetail = (await dispatch(
            getPlanDetail(PlanId)
          )) as unknown as ClassPlanProps;
          const {
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            managementFee,
            performanceFee,
            placementFee,
            exitLoad,
            operatingExpenses,
            exitLoadApplicableTill,
            hurdleRate,
            fundManagerId,
            trusteeId,
            tcLink,
            distributorDetails,
            isApplicableForDirect,
            isoperatingExpensesApplicable,
            operatingExpensesType,
            operatingExpensesInWords,
            setupFeeType,
            setupFeeInWords,
            isAdditionalReturnTextApplicable,
            isHurdleRateTextApplicable,
            hurdleRateText,
            additionalReturnText,
          } = GetFundDetail || {};

          setPlanInitial({
            ...planInitial,
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency: currency || 'inr',
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            managementFee,
            performanceFee,
            placementFee,
            exitLoad,
            operatingExpenses,
            exitLoadApplicableTill,
            hurdleRate,
            fundManagerId,
            trusteeId,
            tcLink,
            distributorIds: distributorDetails.distributorIds || null,
            isApplicableForDirect: isApplicableForDirect || false,
            isApplicableForDistributor: distributorDetails.distributorIds.length > 0 ? true : false,
            operatingExpensesType,
            isoperatingExpensesApplicable,
            operatingExpensesInWords: operatingExpensesInWords || '',
            setupFeeInWords: setupFeeInWords || '',
            setupFeeType,
            isHurdleRateTextApplicable,
            hurdleRateText: hurdleRateText || '',
            isAdditionalReturnTextApplicable,
            additionalReturnText: additionalReturnText || '',
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: NewPlanType) => {
    try {
      const {
        planCode,
        schemeId,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        performanceFee,
        placementFee,
        exitLoad,
        operatingExpenses,
        exitLoadApplicableTill,
        hurdleRate,
        fundManagerId,
        trusteeId,
        tcLink,
        distributorIds,
        isApplicableForDirect,
        isoperatingExpensesApplicable,
        operatingExpensesType,
        operatingExpensesInWords,
        setupFeeInWords,
        setupFeeType,
        hurdleRateText,
        isHurdleRateTextApplicable,
        additionalReturnText,
        isAdditionalReturnTextApplicable,
      } = values;

      const msarFundFields = isMSARFundType(fundType)
        ? {
            performanceFee,
            placementFee,
            exitLoad,
            // operatingExpenses,
            exitLoadApplicableTill,
            hurdleRate,
          }
        : {
            performanceFee: 0,
            placementFee: null,
            exitLoad: 0,
            // operatingExpenses: 0,
            exitLoadApplicableTill: null,
            hurdleRate: 0,
          };

      if (!PlanId) {
        await dispatch(
          addPlan({
            schemeId: fundId,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue: faceValue ? faceValue : faceValue == 0 ? 0 : null,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: !isMSARFundType(fundType)
              ? isFieldPercent(setupFeeType, 'yes')
                ? setupFee
                : 0
              : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            ...msarFundFields,
            tcLink,
            distributorIds,
            isApplicableForDirect,
            isoperatingExpensesApplicable,
            operatingExpensesType: isFieldApplicable(isoperatingExpensesApplicable)
              ? operatingExpensesType
              : null,
            operatingExpensesInWords: isFieldPercent(
              operatingExpensesType,
              isoperatingExpensesApplicable
            )
              ? null
              : makeStringInSingleLine(operatingExpensesInWords || ''),
            operatingExpenses: isFieldPercent(operatingExpensesType, isoperatingExpensesApplicable)
              ? operatingExpenses
              : null,
            setupFeeType: !isMSARFundType(fundType) ? setupFeeType : null,
            setupFeeInWords: !isMSARFundType(fundType)
              ? isFieldPercent(operatingExpensesType, 'yes')
                ? null
                : makeStringInSingleLine(setupFeeInWords || '')
              : null,
            isHurdleRateTextApplicable: !isMSARFundType(fundType)
              ? isHurdleRateTextApplicable
              : null,
            hurdleRateText:
              !isMSARFundType(fundType) && isFieldApplicable(isHurdleRateTextApplicable || '')
                ? makeStringInSingleLine(hurdleRateText || '')
                : null,
            isAdditionalReturnTextApplicable: !isMSARFundType(fundType)
              ? isAdditionalReturnTextApplicable
              : null,
            additionalReturnText:
              !isMSARFundType(fundType) && isFieldApplicable(isAdditionalReturnTextApplicable || '')
                ? makeStringInSingleLine(additionalReturnText || '')
                : null,
          })
        );
      } else {
        await dispatch(
          updatePlan(Number(PlanId), {
            schemeId,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue: faceValue ? faceValue : faceValue == 0 ? 0 : null,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: !isMSARFundType(fundType)
              ? isFieldPercent(setupFeeType, 'yes')
                ? setupFee
                : 0
              : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            ...msarFundFields,
            tcLink,
            distributorIds,
            isApplicableForDirect,
            isoperatingExpensesApplicable,
            operatingExpensesType: isFieldApplicable(isoperatingExpensesApplicable)
              ? operatingExpensesType
              : null,
            operatingExpensesInWords: isFieldPercent(
              operatingExpensesType,
              isoperatingExpensesApplicable
            )
              ? null
              : makeStringInSingleLine(operatingExpensesInWords || ''),
            operatingExpenses: isFieldPercent(operatingExpensesType, isoperatingExpensesApplicable)
              ? operatingExpenses
              : null,
            setupFeeType: !isMSARFundType(fundType) ? setupFeeType : null,
            setupFeeInWords: !isMSARFundType(fundType)
              ? isFieldPercent(setupFeeType, 'yes')
                ? null
                : makeStringInSingleLine(setupFeeInWords || '')
              : null,
            isHurdleRateTextApplicable: !isMSARFundType(fundType)
              ? isHurdleRateTextApplicable
              : null,
            hurdleRateText:
              !isMSARFundType(fundType) && isFieldApplicable(isHurdleRateTextApplicable || '')
                ? makeStringInSingleLine(hurdleRateText || '')
                : null,
            isAdditionalReturnTextApplicable: !isMSARFundType(fundType)
              ? isAdditionalReturnTextApplicable
              : null,
            additionalReturnText:
              !isMSARFundType(fundType) && isFieldApplicable(isAdditionalReturnTextApplicable || '')
                ? makeStringInSingleLine(additionalReturnText || '')
                : null,
          })
        );
      }
      history.push(PlanId ? 'plan-details' : 'view-plans', {
        fundId: fundId,
        PlanId: PlanId,
        fundType: fundType,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={planInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addPlanValidation(fundType)}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                      fundType: fundType,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                      fundType: fundType,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {PlanId ? 'Edit Plan' : 'Add Plan'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                    }}>
                    {'Plan Details'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="planCode"
                      label="Plan Code *"
                      placeholder="Enter Plan Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="planDescription"
                      label="Plan Description *"
                      placeholder="Enter Plan Description"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeNature"
                      label="Scheme Nature *"
                      placeholder="Enter Scheme Nature"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCategory"
                      label="Scheme Category *"
                      placeholder="Enter Scheme Category"
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="registrationNumber"
                      label="Registration Number *"
                      placeholder="Enter Registration Number"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="fundManagerId"
                      label="Investment Manager Name *"
                      items={fundManager.map((item) => ({
                        key: item.name,
                        value: item.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="trusteeId"
                      label="Trustee Name *"
                      items={trustee.map((item) => ({
                        key: item.name,
                        value: item.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="currency"
                      label="Currency"
                      items={Object.keys(CurrencyMaster).map((item) => ({
                        key: CurrencyMaster[item],
                        value: item,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      label={'Scheme Start Date'}
                      inputLabelStyles={{
                        transform: 'unset',

                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                      }}
                      placeholder={'Enter Scheme Start Date'}
                      // maxDate={minDateForContributor()}
                      name={`schemeStartDate`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="lockInPeriod"
                      label="Lock In Period(In Year)"
                      placeholder="Enter Lock In Period(In Year)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="lockInExtension"
                      label="Lock In Extension(In Year)"
                      disabled={!access}
                      placeholder="Enter Lock In Extension(In Year)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="faceValue"
                      label="Face value"
                      placeholder="Enter Face value"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress1"
                      label="Fund Address 1"
                      placeholder="Enter Fund Address 1"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress2"
                      label="Fund Address 2"
                      placeholder="Enter Fund Address 2"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress3"
                      label="Fund Address 3"
                      placeholder="Enter Fund Address 3"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="custodian" label="Custodian" placeholder="Enter Custodian" />
                  </Grid>
                  {fundType && !isMSARFundType(fundType) && (
                    // <Grid item xs={12} sm={6} md={4}>
                    //   <MFTextField
                    //     name="setupFee"
                    //     label="Setup Fee"
                    //     placeholder="Enter Setup Fee"
                    //     type="number"
                    //     onKeyDown={(e) => {
                    //       allowOnlyNumbers(e);
                    //     }}
                    //     trimOnBlur={false}
                    //   />
                    // </Grid>
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <UseRadioGroup
                          formLabel="Setup Fee Type *"
                          name={`setupFeeType`}
                          items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                            label: FEE_TYPE_MASTER[item],
                            value: item,
                          }))}
                        />
                      </Grid>
                      {values.setupFeeType &&
                        (isFieldPercent(values.setupFeeType, 'yes') ? (
                          <Grid item xs={12} sm={6} md={4}>
                            <MFTextField
                              name="setupFee"
                              label="Setup Fee"
                              placeholder="Enter Setup Fee"
                              type="number"
                              onKeyDown={(e) => {
                                allowOnlyNumbers(e);
                              }}
                              trimOnBlur={false}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} md={4}>
                            <MFTextField
                              name="setupFeeInWords"
                              label="Setup Fee *"
                              placeholder="Setup Fee"
                              multiline
                              rows={4.5}
                            />
                          </Grid>
                        ))}
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minCommitmentAmount"
                      label="Minimum Commitment Amount *"
                      placeholder="Enter Minimum Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.minCommitmentAmount != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.minCommitmentAmount, values.currency)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="maxCommitmentAmount"
                      label="Maximum Commitment Amount *"
                      placeholder="Enter Maximum Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.maxCommitmentAmount != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.maxCommitmentAmount, values.currency)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minContributionPercentage"
                      label="Minimum Contribution Percentage"
                      placeholder="Enter Minimum Contribution Percentage"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="managementFee"
                      label={`Management Fee ${isMSARFundType(fundType) ? '*' : ''}`}
                      placeholder="Enter Management Fee"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <UseRadioGroup
                      formLabel="Operating Expense Applicable *"
                      name={`isoperatingExpensesApplicable`}
                      items={Object.keys(YES_NO_MASTER).map((item) => ({
                        label: YES_NO_MASTER[item],
                        value: item,
                      }))}
                    />
                  </Grid>
                  {isFieldApplicable(values.isoperatingExpensesApplicable) && (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={4}>
                        <UseRadioGroup
                          formLabel="Operating Expense Type *"
                          name={`operatingExpensesType`}
                          items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                            label: FEE_TYPE_MASTER[item],
                            value: item,
                          }))}
                        />
                      </Grid>
                      {values.operatingExpensesType &&
                        (isFieldPercent(
                          values.operatingExpensesType,
                          values.isoperatingExpensesApplicable
                        ) ? (
                          <Grid item xs={12} sm={6} md={4}>
                            <MFTextField
                              name="operatingExpenses"
                              label="Operating Expenses % *"
                              placeholder="Enter Operating Expenses %"
                              type="number"
                              onKeyDown={(e) => {
                                allowOnlyNumbers(e);
                              }}
                              trimOnBlur={false}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} md={4}>
                            <MFTextField
                              name="operatingExpensesInWords"
                              label="Operating Expenses *"
                              placeholder="Enter Operating Expenses"
                              multiline
                              rows={4.5}
                            />
                          </Grid>
                        ))}
                    </React.Fragment>
                  )}
                  {isMSARFundType(fundType) && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="performanceFee"
                          label="Performance Fee % *"
                          placeholder="Enter Performance Fee %"
                          type="number"
                          onKeyDown={(e) => {
                            allowOnlyNumbers(e);
                          }}
                          trimOnBlur={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="placementFee"
                          label="Placement fee % *"
                          placeholder="Enter Placement fee %"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="exitLoad"
                          label="Exit Load % *"
                          placeholder="Enter Exit Load %"
                          type="number"
                          onKeyDown={(e) => {
                            allowOnlyNumbers(e);
                          }}
                          trimOnBlur={false}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="operatingExpenses"
                          label="Operating Expenses % *"
                          placeholder="Enter Operating Expenses %"
                          type="number"
                          onKeyDown={(e) => {
                            allowOnlyNumbers(e);
                          }}
                          trimOnBlur={false}
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="exitLoadApplicableTill"
                          label="Exit Load Applicable Till *"
                          placeholder="Enter Exit Load Applicable Till"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="hurdleRate"
                          label="Hurdle Rate % *"
                          placeholder="Enter Hurdle Rate %"
                          type="number"
                          onKeyDown={(e) => {
                            allowOnlyNumbers(e);
                          }}
                          trimOnBlur={false}
                        />
                      </Grid>
                    </>
                  )}
                  {fundType && !isMSARFundType(fundType) && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <UseRadioGroup
                          name="isHurdleRateTextApplicable"
                          formLabel={`Hurdle Rate Applicable *`}
                          items={Object.keys(YES_NO_MASTER).map((item) => ({
                            label: YES_NO_MASTER[item],
                            value: item,
                          }))}
                        />
                      </Grid>
                      {isFieldApplicable(values.isHurdleRateTextApplicable || '') && (
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="hurdleRateText"
                            multiline
                            rows={4.5}
                            label={`Hurdle Rate *`}
                            placeholder={`Enter Hurdle Rate`}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4}>
                        <UseRadioGroup
                          name="isAdditionalReturnTextApplicable"
                          formLabel={`Additional Return Applicable *`}
                          items={Object.keys(YES_NO_MASTER).map((item) => ({
                            label: YES_NO_MASTER[item],
                            value: item,
                          }))}
                        />
                      </Grid>
                      {isFieldApplicable(values.isAdditionalReturnTextApplicable || '') && (
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="additionalReturnText"
                            multiline
                            rows={4.5}
                            label={`Additional Return *`}
                            placeholder={`Enter Additional Return`}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="tcLink" label={`T&C Link`} placeholder={`Enter T&C Link`} />
                  </Grid>
                  {/* empty grid for proper alignment */}
                  <Grid xs={12}></Grid>
                  <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex' }}>
                      <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                      <MFCheckbox
                        name={`isApplicableForDistributor`}
                        label="Distributor"
                        onChange={({ target: { checked } }) => {
                          setValues({
                            ...values,
                            isApplicableForDistributor: checked,
                            distributorIds: [],
                          });
                        }}
                        checked={values.isApplicableForDistributor}
                      />
                    </Box>
                    {values.isApplicableForDistributor && (
                      <MultipleSelect
                        name="distributorIds"
                        label="Applicable Distributor(s) *"
                        items={distributorList.map((item) => ({
                          key: item.id,
                          value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                        }))}
                        renderText="Selected Distributor(s)"
                      />
                    )}
                  </Grid>{' '}
                </Grid>
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
