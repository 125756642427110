import {
  OnBoardingCompletedReportDetailsType,
  OnBoardingReportDetailsType,
} from '../../redux-store/types/api-types';
import { APPLICATION_STATUS, POA_APPLICATION_STATUS } from '../../utils/constant';
import { formatShortDate } from '../../utils/date';
import { ColumnType } from '../DataTable';
import FileSaver from 'file-saver';

export const applicationTypes = [
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'nonIndividual', value: 'Non-Individual' },
  { key: 'minor', value: 'Minor' },
  { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  { key: 'nri_poa', value: 'Individual (NRI-POA)' },
];
export const filterApplicationTypes = [
  { key: 'all', value: 'All' },
  { key: 'individual', value: 'Individual (Resident)' },
  { key: 'non_individual', value: 'Non-Individual' },
  { key: 'minor', value: 'Minor' },
  { key: 'poa', value: 'Individual (POA)' },
  { key: 'nri', value: 'Individual (NRI)' },
  { key: 'nri_poa', value: 'Individual (NRI-POA)' },
];
const commonHeadersForSummaryReports = [
  {
    header: 'Investor Type',
    label: 'investorType',
  },
  {
    header: 'Total Onboardings',
    label: 'total_onboarded',
  },
  {
    header: 'In Progress',
    label: 'in_progress',
  },
  {
    header: 'Draft',
    label: 'draft',
  },
  {
    header: 'Pending with Investor',
    label: 'pending_with_investor',
  },
  {
    header: 'Pending with IM',
    label: 'pending_with_im',
  },
  {
    header: 'Pending with trustee',
    label: 'pending_with_trustee',
  },
  {
    header: 'Expired',
    label: 'invitationexpired',
  },
  {
    header: 'Rejected',
    label: 'rejected',
  },
  {
    header: 'Completed',
    label: 'completed',
  },
];

export const onboardingDetailsDownload = [
  'Export',
  'Download Current Fields',
  'Download All Fields',
];

export const reportData: {
  value: string;
  key: string;
  tableHeaders: ColumnType[];
}[] = [
  {
    value: 'onboardingSummary',
    key: 'Onboarding Summary',
    tableHeaders: commonHeadersForSummaryReports,
  },
  {
    value: 'onboardingDetails',
    key: 'Onboarding Details',
    tableHeaders: [
      {
        header: 'First Holder Name',
        label: 'firstHolderName',
        valueGetter: (row: OnBoardingReportDetailsType) => row.firstHolderName || 'NA',
      },
      {
        header: 'Application ID',
        label: 'applicationNumber',
        valueGetter: (row: OnBoardingReportDetailsType) => row.applicationNumber || 'NA',
      },
      {
        header: 'Mode of Holding',
        valueGetter: (row: OnBoardingReportDetailsType) => row.modeOfHolding || 'NA',
      },
      {
        header: 'Scheme',
        valueGetter: (row: OnBoardingReportDetailsType) => row.scheme || 'NA',
      },
      {
        header: 'Date of onboarding',
        label: 'createdAt',
        valueGetter: (row: OnBoardingReportDetailsType) => formatShortDate(row.createdAt) || 'NA',
      },
      {
        header: 'Committed Value',
        valueGetter: (row: OnBoardingReportDetailsType) => row.commitmentAmount || 'NA',
      },
      {
        header: 'First Holder PAN',
        valueGetter: (row: OnBoardingReportDetailsType) => row.firstHolderPAN || 'NA',
      },
      {
        header: 'Distributor Name',
        valueGetter: (row: OnBoardingReportDetailsType) => row.distributor || 'NA',
      },
      {
        header: 'Leegality Document Id',
        valueGetter: (row: OnBoardingReportDetailsType) => row.leegalityDocumentId || 'NA',
      },
      {
        header: 'Status',
        valueGetter: ({ status }: OnBoardingReportDetailsType) =>
          APPLICATION_STATUS[status] || POA_APPLICATION_STATUS[status] || 'NA',
      },
    ],
  },
  {
    value: 'distributorOnBoardingSummary',
    key: 'Distributor wise onBoarding summary',
    tableHeaders: commonHeadersForSummaryReports,
  },
  // {
  //   value: 'distributorOnBoardingDetail',
  //   key: 'Distributor wise onBoarding details',
  //   tableHeaders: [],
  // },
  {
    value: 'distributorCommitmentAmount',
    key: 'Distributor wise commitment amount report',
    tableHeaders: [
      {
        header: 'Investor Type',
        label: 'investorType',
      },
      {
        header: 'Completed',
        label: 'completed',
      },
      {
        header: 'Commitment Amount',
        label: 'commitmentAmount',
      },
      // {
      //   header: 'Contribution Amount',
      //   label: 'contributionAmount',
      // },
    ],
  },
  {
    value: 'onboardingCompletedDetails',
    key: 'Onboarding Completed Details',
    tableHeaders: [
      {
        header: 'Application ID',
        label: 'applicationNumber',
        valueGetter: (row: OnBoardingCompletedReportDetailsType) => row.applicationNumber || 'NA',
      },
      {
        header: 'First Holder Name',
        label: 'firstHolderName',
        valueGetter: (row: OnBoardingCompletedReportDetailsType) => row.firstHolderName || 'NA',
      },
      {
        header: 'Folio Number',
        valueGetter: (row: OnBoardingCompletedReportDetailsType) => row.folio_no || 'NA',
      },
      {
        header: 'Distributor Name',
        valueGetter: (row: OnBoardingCompletedReportDetailsType) => row.distributor || 'NA',
      },
      {
        header: 'First Holder PAN',
        valueGetter: (row: OnBoardingCompletedReportDetailsType) => row.firstHolderPAN || 'NA',
      },
      {
        header: 'Date of completion',
        label: 'updatedAt',
        valueGetter: (row: OnBoardingCompletedReportDetailsType) =>
          formatShortDate(row.updatedAt) || 'NA',
      },
    ],
  },
];

export const getFromDate = (): string => {
  const date = new Date();
  date.setDate(1);
  return date.toISOString();
};
export const reportDownload = (res: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(res as unknown as Blob);
  FileSaver.saveAs(url, fileName);
};

export const filterApplicationStatusTypes = [
  { key: 'draft', value: 'Draft' },
  { key: 'sent_to_applicant1', value: 'Sent to 1st applicant' },
  { key: 'approved_by_applicant1', value: 'Approved by 1st Applicant' },
  { key: 'approved_by_applicant2', value: 'Approved by 2nd Applicant' },
  { key: 'approved_by_applicant3', value: 'Approved by 3rd Applicant' },
  { key: 'approved_by_fundmanager', value: 'Approved by investment manager' },
  { key: 'invitationexpired', value: 'Invitation Expired' },
  { key: 'completed,signed', value: 'Completed' },
  { key: 'rejected', value: 'Rejected' },
  {
    key: 'sent_to_poa_approver',
    value: 'Sent to POA approver',
  },
  {
    key: 'sent_to_amc_approver',
    value: 'Sent to AIF approver',
  },
  {
    key: 'approved_by_amc_approver',
    value: 'Approved by AIF approver',
  },
  { key: 'sent_to_authorised_signatories', value: 'Sent to authorised signatories' },
  { key: 'approved_by_authorised_signatories', value: 'Approved by authorised signatories' },
];
