import { Typography } from '@mui/material';
import React from 'react';
import { Item } from '../Investments/investor-application';

export default function LayoutMultiLine({
  title,
  description,
}: {
  title: string;
  description?: string;
}): JSX.Element {
  return (
    <>
      <Item
        sx={{
          fontSize: 15,
          fontWeight: 500,
          color: 'rgba(0, 0, 0, 0.6)',
          mb: 1,
          fontFamily: 'Work Sans,sans-serif',
        }}>
        {title}
      </Item>
      {description ? (
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1,
            fontFamily: 'Work Sans,sans-serif',
          }}>
          {description}
        </Typography>
      ) : (
        '-'
      )}
    </>
  );
}
