import {
  Box,
  Button,
  Typography,
  Hidden,
  IconButton,
  Tooltip,
  Grid,
  Stack,
  Switch,
  Dialog,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import ManagementTable, { ColumnType } from './ManagementTable';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { DataTable } from '../DataTable';
import { Link as RouterLink } from 'react-router-dom';
import { editRM, getRMsList } from '../../redux-store/actions/userManagement';
import { newRmType, RmsType, addRmDetails } from '../../redux-store/types/api-types';
import { ConfirmationDialog } from '../commonComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

export const AccessHeader = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            color: 'primary.main',
            fontWeight: 500,
          }}
          component="span">
          Access
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            onClick={handleOpen}
            sx={{ position: 'relative', top: 4 }}
          />
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 12,
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Enable/Disable
        </Typography>
      </Box>

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 550,
            p: { xs: 2, sm: '15px 20px 45px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
          <InfoOutlinedIcon color="info" fontSize="large" />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>
            Disabling the access of the user will log him out of the active session and he will not
            be able to access the portal.
          </Typography>
          <Typography sx={{ color: 'secondary.main', lineHeight: '35px', mt: 2 }}>
            Please make sure there are no draft and In-progress applications for the user before
            disabling the user .
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

const headers = [
  {
    header: 'RM Name',
    renderCell: (row: RmsType) => {
      return (
        <Typography
          variant="h5"
          sx={{
            color: '#61D1D6',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: '14px',
            ':hover': {
              color: 'primary.main',
            },
          }}
          component={RouterLink}
          to={{ pathname: 'relationship-manager-details', state: { rmId: row.id } }}>
          {row.user?.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Email Id',
    label: 'email',
    valueGetter: (row: RmsType) => row.user?.email || 'N/A',
  },
  {
    header: 'Contact Details',
    label: 'contact',
    valueGetter: (row: RmsType) => row.user?.phone || 'N/A',
  },
  {
    header: 'PAN',
    label: 'panNumber',
    valueGetter: (row: RmsType) => row.panNumber || 'N/A',
  },
  {
    header: 'EMP Code',
    label: 'empCode',
    valueGetter: (row: RmsType) => row.empCode || 'N/A',
  },
];

export default function RelationshipManagers(): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [RMData, setRMData] = useState<RmsType[]>();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{ rmRowData: addRmDetails } | null>(null);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getRMsList())) as unknown as newRmType;
        if (!isComponentActive) {
          return;
        }
        const { rms } = res || {};
        setRMData(rms);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessViewOrEditCell = [
    {
      header: AccessHeader,
      renderCell: (row: addRmDetails): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ rmRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: RmsType): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               // onClick={() => {
    //               //  history.push(`/application`, { id: row.id });
    //               // }}
    //               component={RouterLink}
    //               to={{ pathname: 'edit-rm-details', state: { rmId: row.id } }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{ pathname: 'edit-rm-details', state: { rmId: row.id } }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      header: "View SRM's",
      renderCell: (row: RmsType): JSX.Element => {
        return (
          <Typography
            variant="h5"
            component={RouterLink}
            to={{ pathname: 'srms', state: { rmId: row.id } }}
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}>
            View
          </Typography>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>
            List of Relationship Managers
          </Typography>
          <Hidden only="xs">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                color: 'white',
                fontWeight: 500,
                fontSize: 14,
                mr: 2.5,
                py: 0.5,
                px: 3,
              }}
              onClick={() => history.push('invite-rm-details')}>
              Invite RM
            </Button>
          </Hidden>
          <Hidden smUp={true}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 13,
                // mr: 2.5,
                py: 0.5,
                // px: 3,
                // whiteSpace: 'nowrap',
              }}
              onClick={() => history.push('invite-rm-details')}>
              Add RM
            </Button>
          </Hidden>
        </Box>

        <Grid item xs={12} sm={12} lg={12}>
          {useMemo(
            () => (
              <DataTable
                tableData={RMData || []}
                tableHeader={[...headers, ...AccessViewOrEditCell]}
                isLoading={isLoading}
                currentPage={currentPage}
                noOfPages={1}
                paginationAction={handlePageChange}
                renderAdditionalRow={true}
                tableBodyCustomStyles={{
                  '.MuiTableCell-root': {
                    borderBottom: 'none',
                  },
                }}
                tableHeaderCustomStyles={{
                  '&:last-child th': { border: 0 },
                  '.MuiTableCell-head': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'primary.main',
                  },
                }}
              />
            ),
            [headers, isLoading, RMData]
          )}
        </Grid>

        {/* <ManagementTable tableData={RMData || []} tableHeader={headers} isLoading={isLoading} /> */}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.rmRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                editRM(openDialog.rmRowData.id as number, {
                  ...openDialog.rmRowData,
                  isActive: !openDialog?.rmRowData.isActive,
                })
              )) as unknown as RmsType;
              setRMData(
                RMData?.map((rm) => {
                  if (Number(rm.id) === Number(updatedResponse.id)) {
                    return {
                      ...rm,
                      ...updatedResponse,
                    };
                  }
                  return rm;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
