import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import MFSelectField from '../../lib/formik/SelectField';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import {
  addFund,
  addPlan,
  fundAccess,
  getPlanList,
  updateFund,
} from '../../redux-store/actions/funds';
import { Location } from 'history';
import {
  accessType,
  addFundPlanType,
  ClassPlanProps,
  FundProps,
} from '../../redux-store/types/funds';
import { addFundValidation, editFundValidation } from '../../utils/schema';
import {
  allowOnlyNumbers,
  currencyConversion,
  isFieldApplicable,
  isFieldPercent,
  isMSARFundType,
  makeStringInSingleLine,
} from '../../utils/utilityFunctions';
import {
  CurrencyMaster,
  FEE_TYPE_MASTER,
  FUND_TYPE_MASTER,
  FundCategoryMasters,
  YES_NO_MASTER,
} from '../../utils/constant';
import UseRadioGroup from '../../lib/formik/Radio';
import { DistributorListType, FundManager, Trustee } from '../../redux-store/types/api-types';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import MFCheckbox from '../../lib/formik/Checkbox';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';

type NewPlanType = addFundPlanType & {
  isApplicableForDistributor: boolean;
};
const initialValues: NewPlanType = {
  schemeCode: '',
  schemeName: '',
  amcCode: '',
  bankName: '',
  bankBranch: '',
  ifscCode: '',
  bankAccountNumber: '',
  id: '',
  schemeId: null,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: 'inr',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  inActive: true,
  faceValue: 0,
  fundAddress1: '',
  fundAddress2: '',
  fundAddress3: '',
  isActive: true,
  custodian: '',
  setupFee: 0,
  fundManagerId: null,
  trusteeId: null,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  managementFee: 0,
  performanceFee: 0,
  placementFee: '',
  exitLoad: 0,
  operatingExpenses: 0,
  exitLoadApplicableTill: '',
  hurdleRate: 0,
  tcLink: '',
  fundCategory: '',
  fundType: '',
  distributorIds: null,
  isApplicableForDirect: false,
  isApplicableForDistributor: false,
  isoperatingExpensesApplicable: '',
  operatingExpensesType: '',
  operatingExpensesInWords: '',
  setupFeeType: '',
  setupFeeInWords: '',
  isHurdleRateTextApplicable: '',
  hurdleRateText: '',
  isAdditionalReturnTextApplicable: '',
  additionalReturnText: '',
};

export default function AddFund({
  location,
}: {
  location: Location<{ fundId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<any[]>([]);
  const [editField, setEditField] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { fundId } = location.state || {};
  const [access, setAccess] = useState(false);
  const [trustee, setTrustee] = useState<any[]>([]);
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(
          getTrustessList({ isActive: true })
        )) as unknown as Trustee[];
        const fundManagerList = (await dispatch(
          getFundManagersList({ isActive: true })
        )) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList);
        setTrustee(trusteeList);
        const res = (await dispatch(
          getDistributorsListForFilters()
        )) as unknown as DistributorListType[];
        setDistributorList(res);

        if (fundId) {
          const GetFundDetail = (await dispatch(
            getPlanList(fundId.toString())
          )) as unknown as FundProps;
          const {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            fundCategory,
            fundType,
          } = GetFundDetail || {};

          setEditField({
            ...editField,
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            fundCategory,
            fundType,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: NewPlanType) => {
    try {
      const {
        schemeCode,
        schemeName,
        amcCode,
        bankName,
        bankBranch,
        ifscCode,
        bankAccountNumber,
        fundCategory,
        fundType,
      } = values;

      const {
        planCode,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        performanceFee,
        placementFee,
        exitLoad,
        operatingExpenses,
        exitLoadApplicableTill,
        hurdleRate,
        fundManagerId,
        trusteeId,
        tcLink,
        distributorIds,
        isApplicableForDirect,
        isoperatingExpensesApplicable,
        operatingExpensesType,
        operatingExpensesInWords,
        setupFeeType,
        setupFeeInWords,
        isHurdleRateTextApplicable,
        hurdleRateText,
        isAdditionalReturnTextApplicable,
        additionalReturnText,
      } = values;

      const msarFundFields = isMSARFundType(fundType)
        ? {
            performanceFee,
            placementFee,
            exitLoad,
            // operatingExpenses,
            exitLoadApplicableTill,
            hurdleRate,
          }
        : {
            performanceFee: 0,
            placementFee: null,
            exitLoad: 0,
            // operatingExpenses: 0,
            exitLoadApplicableTill: null,
            hurdleRate: 0,
          };

      if (fundId) {
        await dispatch(
          updateFund(fundId, {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            fundCategory,
            fundType,
          })
        );
      } else {
        const fundRes = (await dispatch(
          addFund({
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            fundCategory,
            fundType,
          })
        )) as unknown as FundProps;

        await dispatch(
          addPlan({
            schemeId: fundRes.id,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: !isMSARFundType(values.fundType)
              ? isFieldPercent(setupFeeType, 'yes')
                ? setupFee
                : 0
              : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            ...msarFundFields,
            tcLink,
            distributorIds,
            isApplicableForDirect,
            isoperatingExpensesApplicable,
            operatingExpensesType: isFieldApplicable(isoperatingExpensesApplicable)
              ? operatingExpensesType
              : null,
            operatingExpenses: isFieldPercent(operatingExpensesType, isoperatingExpensesApplicable)
              ? operatingExpenses
              : null,
            operatingExpensesInWords: isFieldPercent(
              operatingExpensesType,
              isoperatingExpensesApplicable
            )
              ? null
              : makeStringInSingleLine(operatingExpensesInWords || ''),
            setupFeeType: !isMSARFundType(values.fundType) ? setupFeeType : null,
            setupFeeInWords: !isMSARFundType(values.fundType)
              ? isFieldPercent(setupFeeType, 'yes')
                ? null
                : makeStringInSingleLine(setupFeeInWords || '')
              : null,
            isHurdleRateTextApplicable: !isMSARFundType(fundType)
              ? isHurdleRateTextApplicable
              : null,
            hurdleRateText:
              !isMSARFundType(fundType) && isFieldApplicable(isHurdleRateTextApplicable || '')
                ? makeStringInSingleLine(hurdleRateText || '')
                : null,
            isAdditionalReturnTextApplicable: !isMSARFundType(fundType)
              ? isAdditionalReturnTextApplicable
              : null,
            additionalReturnText:
              !isMSARFundType(fundType) && isFieldApplicable(isAdditionalReturnTextApplicable || '')
                ? makeStringInSingleLine(additionalReturnText || '')
                : null,
          }) as unknown as ClassPlanProps
        );
      }
      history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={editField}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={fundId ? editFundValidation : addFundValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {fundId ? 'Edit Fund' : 'Add Fund'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                      // backgroundColor: 'red',
                    }}>
                    {'Fund Details(Scheme)'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCode"
                      label="Scheme Code *"
                      placeholder="Enter Scheme Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeName"
                      label="Scheme Name *"
                      placeholder="Enter Scheme Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="amcCode" label="AMC Code" placeholder="Enter AMC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="bankName" label="Bank Name" placeholder="Enter Bank Name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankBranch"
                      label="Bank Branch"
                      placeholder="Enter Bank Branch"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="ifscCode" label="IFSC Code" placeholder="Enter IFSC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankAccountNumber"
                      label="Bank Account Number"
                      placeholder="Enter Bank Account Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
                    <UseRadioGroup
                      formLabel="Fund Category *"
                      name={`fundCategory`}
                      items={Object.keys(FundCategoryMasters).map((Id) => ({
                        label: FundCategoryMasters[Id],
                        value: Id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
                    <UseRadioGroup
                      formLabel="Fund Type *"
                      name={`fundType`}
                      items={Object.keys(FUND_TYPE_MASTER).map((item) => ({
                        label: FUND_TYPE_MASTER[item],
                        value: item,
                      }))}
                    />
                  </Grid>
                </Grid>
                {!fundId && (
                  <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                    <SubHeading
                      sx={{
                        color: 'common.blue',
                        letterSpacing: 0.5 + 'px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                        ml: { xs: 3.5 },
                      }}>
                      {'Plan Details'}
                    </SubHeading>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="planCode"
                        label="Plan Code *"
                        placeholder="Enter Plan Code"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="planDescription"
                        label="Plan Description *"
                        placeholder="Enter Plan Description"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="schemeNature"
                        label="Scheme Nature *"
                        placeholder="Enter Scheme Nature"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="registrationNumber"
                        label="Registration Number *"
                        placeholder="Enter Registration Number"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="fundManagerId"
                        label="Investment Manager Name *"
                        items={fundManager.map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="trusteeId"
                        label="Trustee Name *"
                        items={trustee.map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="schemeCategory"
                        label="Scheme Category *"
                        placeholder="Enter Scheme Category"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField name="country" label="Country" placeholder="Enter Country" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="currency"
                        label="Currency"
                        items={Object.keys(CurrencyMaster).map((item) => ({
                          key: CurrencyMaster[item],
                          value: item,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DatePicker
                        label={'Scheme Start Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 14,
                          fontWeight: 500,

                          color: 'rgba(0,0,0,0.7)',
                        }}
                        placeholder={'Enter Scheme Start Date'}
                        name={`schemeStartDate`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="lockInPeriod"
                        label="Lock In Period(In Year)"
                        placeholder="Enter Lock In Period(In Year)"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="lockInExtension"
                        label="Lock In Extension(In Year)"
                        placeholder="Enter Lock In Extension(In Year)"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="faceValue"
                        label="Face value"
                        placeholder="Enter Face value"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress1"
                        label="Fund Address 1"
                        placeholder="Enter Fund Address 1"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress2"
                        label="Fund Address 2"
                        placeholder="Enter Fund Address 2"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress3"
                        label="Fund Address 3"
                        placeholder="Enter Fund Address 3"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="custodian"
                        label="Custodian"
                        placeholder="Enter Custodian"
                      />
                    </Grid>
                    {values.fundType && !isMSARFundType(values.fundType) && (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <UseRadioGroup
                            formLabel="Setup Fee Type *"
                            name={`setupFeeType`}
                            items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                              label: FEE_TYPE_MASTER[item],
                              value: item,
                            }))}
                          />
                        </Grid>
                        {values.setupFeeType &&
                          (isFieldPercent(values.setupFeeType, 'yes') ? (
                            <Grid item xs={12} sm={6} md={4}>
                              <MFTextField
                                name="setupFee"
                                label="Setup Fee"
                                placeholder="Enter Setup Fee"
                                type="number"
                                onKeyDown={(e) => {
                                  allowOnlyNumbers(e);
                                }}
                                trimOnBlur={false}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} md={4}>
                              <MFTextField
                                name="setupFeeInWords"
                                label="Setup Fee *"
                                placeholder="Setup Fee"
                                multiline
                                rows={4.5}
                                trimOnBlur={false}
                              />
                            </Grid>
                          ))}
                      </>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="minCommitmentAmount"
                        label="Minimum Commitment Amount *"
                        placeholder="Enter Minimum Commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.minCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.minCommitmentAmount, values.currency)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="maxCommitmentAmount"
                        label="Maximum Commitment Amount *"
                        placeholder="Enter Maximum Commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.maxCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.maxCommitmentAmount, values.currency)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="minContributionPercentage"
                        label="Minimum Contribution Percentage"
                        placeholder="Enter Minimum Contribution Percentage"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="managementFee"
                        label={`Management Fee ${isMSARFundType(values.fundType) ? '*' : ''}`}
                        placeholder="Enter Management Fee"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <UseRadioGroup
                        formLabel="Operating Expense Applicable *"
                        name={`isoperatingExpensesApplicable`}
                        items={Object.keys(YES_NO_MASTER).map((item) => ({
                          label: YES_NO_MASTER[item],
                          value: item,
                        }))}
                      />
                    </Grid>
                    {isFieldApplicable(values.isoperatingExpensesApplicable) && (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <UseRadioGroup
                            formLabel="Operating Expense Type *"
                            name={`operatingExpensesType`}
                            items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                              label: FEE_TYPE_MASTER[item],
                              value: item,
                            }))}
                          />
                        </Grid>
                        {values.operatingExpensesType &&
                          (isFieldPercent(
                            values.operatingExpensesType,
                            values.isoperatingExpensesApplicable
                          ) ? (
                            <Grid item xs={12} sm={6} md={4}>
                              <MFTextField
                                name="operatingExpenses"
                                label="Operating Expenses % *"
                                placeholder="Enter Operating Expenses %"
                                type="number"
                                onKeyDown={(e) => {
                                  allowOnlyNumbers(e);
                                }}
                                trimOnBlur={false}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} md={4}>
                              <MFTextField
                                name="operatingExpensesInWords"
                                label="Operating Expenses *"
                                placeholder="Enter Operating Expenses"
                                multiline
                                rows={4.5}
                                trimOnBlur={false}
                              />
                            </Grid>
                          ))}
                      </>
                    )}
                    {isMSARFundType(values.fundType) && (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="performanceFee"
                            label="Performance Fee % *"
                            placeholder="Enter Performance Fee %"
                            type="number"
                            onKeyDown={(e) => {
                              allowOnlyNumbers(e);
                            }}
                            trimOnBlur={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="placementFee"
                            label="Placement fee % *"
                            placeholder="Enter Placement fee %"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="exitLoad"
                            label="Exit Load % *"
                            placeholder="Enter Exit Load %"
                            type="number"
                            onKeyDown={(e) => {
                              allowOnlyNumbers(e);
                            }}
                            trimOnBlur={false}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="exitLoadApplicableTill"
                            label="Exit Load Applicable Till *"
                            placeholder="Enter Exit Load Applicable Till"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <MFTextField
                            name="hurdleRate"
                            label="Hurdle Rate % *"
                            placeholder="Enter Hurdle Rate %"
                            type="number"
                            onKeyDown={(e) => {
                              allowOnlyNumbers(e);
                            }}
                            trimOnBlur={false}
                          />
                        </Grid>
                      </>
                    )}
                    {values.fundType && !isMSARFundType(values.fundType) && (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <UseRadioGroup
                            name="isHurdleRateTextApplicable"
                            formLabel={`Hurdle Rate Applicable *`}
                            items={Object.keys(YES_NO_MASTER).map((item) => ({
                              label: YES_NO_MASTER[item],
                              value: item,
                            }))}
                          />
                        </Grid>
                        {isFieldApplicable(values.isHurdleRateTextApplicable || '') && (
                          <Grid item xs={12} sm={6} md={4}>
                            <MFTextField
                              name="hurdleRateText"
                              multiline
                              rows={4.5}
                              label={`Hurdle Rate *`}
                              placeholder={`Enter Hurdle Rate`}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4}>
                          <UseRadioGroup
                            name="isAdditionalReturnTextApplicable"
                            formLabel={`Additional Return Applicable *`}
                            items={Object.keys(YES_NO_MASTER).map((item) => ({
                              label: YES_NO_MASTER[item],
                              value: item,
                            }))}
                          />
                        </Grid>
                        {isFieldApplicable(values.isAdditionalReturnTextApplicable || '') && (
                          <Grid item xs={12} sm={6} md={4}>
                            <MFTextField
                              name="additionalReturnText"
                              multiline
                              rows={4.5}
                              label={`Additional Return *`}
                              placeholder={`Enter Additional Return`}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="tcLink"
                        label={`T&C Link`}
                        placeholder={`Enter T&C Link`}
                      />
                    </Grid>
                    {/* empty grid for proper alignment */}
                    <Grid xs={12}></Grid>
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex' }}>
                        <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                        <MFCheckbox
                          name={`isApplicableForDistributor`}
                          label="Distributor"
                          onChange={({ target: { checked } }) => {
                            setValues({
                              ...values,
                              isApplicableForDistributor: checked,
                              distributorIds: [],
                            });
                          }}
                          checked={values.isApplicableForDistributor}
                        />
                      </Box>
                      {values.isApplicableForDistributor && (
                        <MultipleSelect
                          name="distributorIds"
                          label="Applicable Distributor(s) *"
                          items={distributorList.map((item) => ({
                            key: item.id,
                            value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                          }))}
                          renderText="Selected Distributor(s)"
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
