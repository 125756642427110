import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { CommonLayout, CountryCodesDropDown } from './commonComponents';
import { MFSubmitButton, MFTextField } from '../lib/formik';
import MFSelectField from '../lib/formik/SelectField';
import { showError } from '../redux-store/actions/auth';
import {
  adminSupportMaster,
  ENABLE_PROCESS_SIGNING_APPLICATIONS,
  SUPPORT_STATUS,
  USER_ROLES,
} from '../utils/constant';
import { adminSupportValidation } from '../utils/schema';
import { preventSpecialCharacters } from '../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../redux-store/reducers';
import {
  unlockUser,
  checkWhitelistUser,
  whitelistUser,
  checkWhitelistedResponceBody,
  supportTypeBody,
  unlockAccess,
  WhitelistingAccess,
  unlockAccessBody,
  whitelistAccessBody,
  changeStatusAccess,
  statusAccessBody,
  changeStatusToDraft,
  changeStatusFromExpiredexport,
  processSignedApplications,
} from '../redux-store/actions/adminSupport';
import { nonDigitRegex } from '../utils/regex';

export interface adminSupportValues {
  supportType: string;
  phone: string;
  countryCode: string;
  application_number: string;
  status: string;
}

const adminSupportInitialValues: adminSupportValues = {
  supportType: '',
  phone: '',
  countryCode: 'India: +91',
  status: '',
  application_number: '',
};

export default function adminSupport(): JSX.Element {
  const dispatch = useDispatch();
  const [unlockAcc, setUnlockAcc] = useState(false);
  const [whitelistAccess, setWhitelistAccess] = useState(false);
  const [statusAccess, setStatusAccess] = useState(false);
  const { adminSupport } = useSelector((store: RootStateType) => store.auth);

  useEffect(() => {
    (async function () {
      try {
        if (adminSupport) {
          const unlockAccessResponse = (await dispatch(
            unlockAccess()
          )) as unknown as unlockAccessBody;
          setUnlockAcc(unlockAccessResponse.unlockingAccess);
          const whitelistAccessResponse = (await dispatch(
            WhitelistingAccess()
          )) as unknown as whitelistAccessBody;
          setWhitelistAccess(whitelistAccessResponse.whitelistingAccess);
          const statusAccessResponse = (await dispatch(
            changeStatusAccess()
          )) as unknown as statusAccessBody;
          setStatusAccess(statusAccessResponse.applicationStatusChangeAccess);
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const supportOptions = Object.keys(adminSupportMaster).filter((item) => {
    if (unlockAcc && adminSupportMaster[item] === adminSupportMaster.unlock_user) {
      return item;
    }
    if (
      whitelistAccess &&
      [
        adminSupportMaster.check_whitelisted,
        adminSupportMaster.whitelist_user,
        adminSupportMaster.unwhitelist_user,
      ].includes(adminSupportMaster[item])
      // (adminSupportMaster[item] === adminSupportMaster.check_whitelisted ||
      //   adminSupportMaster[item] === adminSupportMaster.whitelist_user ||
      //   adminSupportMaster[item] === adminSupportMaster.unwhitelist_user)
    ) {
      return item;
    }
    if (
      statusAccess &&
      [
        adminSupportMaster.change_expired_status,
        adminSupportMaster.change_status_to_draft,
      ].includes(adminSupportMaster[item])
      // (adminSupportMaster[item] === adminSupportMaster.change_expired_status ||
      //   adminSupportMaster[item] === adminSupportMaster.change_status_to_draft)
    ) {
      return item;
    }
    if (
      ENABLE_PROCESS_SIGNING_APPLICATIONS &&
      adminSupportMaster[item] === adminSupportMaster.process_signed_applications
    ) {
      return item;
    }
    return;
  });

  const handleSubmit = async (values: adminSupportValues) => {
    console.log(values);
    try {
      if (adminSupportMaster[values.supportType] === adminSupportMaster.unlock_user) {
        const unlockResponse = (await dispatch(
          unlockUser({ phone: values.phone, countryCode: values.countryCode?.split(':')[1].trim() })
        )) as unknown as supportTypeBody;
        throw `${unlockResponse.message}`;
      }
      if (adminSupportMaster[values.supportType] === adminSupportMaster.check_whitelisted) {
        const checkWhitelistResponse = (await dispatch(
          checkWhitelistUser({
            phone: values.phone,
            countryCode: values.countryCode?.split(':')[1].trim(),
          })
        )) as unknown as checkWhitelistedResponceBody;
        throw `${
          checkWhitelistResponse.isMobileNumberWhiteListed
            ? 'This Number is Whitelisted'
            : 'This Number is not Whitelisted'
        }`;
      }
      if (adminSupportMaster[values.supportType] === adminSupportMaster.change_expired_status) {
        const changeStatusFromExpired = (await dispatch(
          changeStatusFromExpiredexport({
            applicationStatus: values.status,
            applicationNumber: values.application_number,
          })
        )) as unknown as supportTypeBody;
        throw `${changeStatusFromExpired.message}`;
      }
      if (adminSupportMaster[values.supportType] === adminSupportMaster.change_status_to_draft) {
        const changeStatusToDraftRes = (await dispatch(
          changeStatusToDraft({
            applicationNumber: values.application_number,
          })
        )) as unknown as supportTypeBody;
        throw `${changeStatusToDraftRes.message}`;
      }
      if (
        [adminSupportMaster.whitelist_user, adminSupportMaster.unwhitelist_user].includes(
          adminSupportMaster[values.supportType]
        )
        // adminSupportMaster[values.supportType] === adminSupportMaster.whitelist_user ||
        // adminSupportMaster[values.supportType] === adminSupportMaster.unwhitelist_user
      ) {
        const whitelistResponse = (await dispatch(
          whitelistUser({
            phone: values.phone,
            countryCode: values.countryCode?.split(':')[1].trim(),
            whiteList:
              adminSupportMaster[values.supportType] === adminSupportMaster.whitelist_user
                ? true
                : false,
          })
        )) as unknown as supportTypeBody;
        throw `${whitelistResponse.message}`;
      }
      if (
        ENABLE_PROCESS_SIGNING_APPLICATIONS &&
        adminSupportMaster[values.supportType] === adminSupportMaster.process_signed_applications
      ) {
        await dispatch(processSignedApplications());
        throw `Process Signed Applications Successfully`;
      }
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  const onSupportTypeChange = (supportType: any, values: any, setValues: any) => {
    if (adminSupportMaster[supportType] === adminSupportMaster.change_expired_status) {
      setValues({
        ...values,
        supportType: supportType,
        phone: '',
      });
      return;
    }

    if (adminSupportMaster[supportType] === adminSupportMaster.change_status_to_draft) {
      setValues({
        ...values,
        supportType: supportType,
        phone: '',
        status: '',
      });
      return;
    }

    setValues({
      ...values,
      supportType: supportType,
      application_number: '',
      status: '',
    });
  };
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('phone');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);
  return (
    <CommonLayout>
      <Formik
        initialValues={adminSupportInitialValues}
        onSubmit={handleSubmit}
        validate={(values: adminSupportValues) => {
          // console.log(values);
          try {
            validateYupSchema(values, adminSupportValidation, true, values);
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}
        enableReinitialize={true}>
        {({ handleSubmit, values, setValues }) => {
          return (
            <>
              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 18,
                    fontWeight: 500,
                    mb: { xs: 2, md: 4 },
                    p: { xs: '5px 15px', md: '' },
                    bgcolor: '#e5f1f8',
                    borderRadius: '10px',
                  }}>
                  Admin Support
                </Typography>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={10}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    width: '100%',
                    ml: 0,
                    '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
                    p: '20px 30px',
                    bgcolor: 'common.white',
                    mt: 1.5,
                  }}>
                  <Grid sx={{ width: '100%' }} container rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Grid xs={12} sm={12}>
                        <MFSelectField
                          name={`supportType`}
                          label={'Support Type *'}
                          items={supportOptions.map((item) => ({
                            key: adminSupportMaster[item],
                            value: item,
                          }))}
                          onChange={(e) => {
                            // setFieldValue('supportType', e.target.value);
                            onSupportTypeChange(e.target.value, values, setValues);
                          }}
                          applyLabelStyles={true}
                        />
                      </Grid>
                    </Grid>
                    {![
                      adminSupportMaster.change_expired_status,
                      adminSupportMaster.change_status_to_draft,
                      adminSupportMaster.process_signed_applications,
                    ].includes(adminSupportMaster[values.supportType]) && (
                      <Grid item xs={12} sm={6}>
                        <MFTextField
                          name={`phone`}
                          label={`Mobile Number *`}
                          placeholder="Enter Mobile Number"
                          onKeyDown={(e) => {
                            preventSpecialCharacters(e);
                          }}
                          startAdornment={
                            <CountryCodesDropDown
                              ElementWidth={mobileElementWidth && mobileElementWidth}
                              name={`countryCode`}
                              value={values.countryCode}
                            />
                          }
                          regexForFilterValue={nonDigitRegex}
                        />
                      </Grid>
                    )}
                    {[
                      adminSupportMaster.change_expired_status,
                      adminSupportMaster.change_status_to_draft,
                    ].includes(adminSupportMaster[values.supportType]) && (
                      <Grid item xs={12} sm={6}>
                        <MFTextField
                          name={`application_number`}
                          label={`Application Number *`}
                          placeholder="Enter Application Number"
                        />
                      </Grid>
                    )}
                    {adminSupportMaster[values.supportType] ===
                      adminSupportMaster.change_expired_status && (
                      <Grid item xs={12} sm={6}>
                        <Grid xs={12} sm={12}>
                          <MFSelectField
                            name={`status`}
                            label={'Status'}
                            items={Object.keys(SUPPORT_STATUS).map((item) => ({
                              key: SUPPORT_STATUS[item],
                              value: item,
                            }))}
                            applyLabelStyles={true}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 5, mb: 5 }}>
                    <MFSubmitButton label="Submit" />
                  </Box>
                </Grid>
              </Box>
            </>
          );
        }}
      </Formik>
    </CommonLayout>
  );
}
