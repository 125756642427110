import {
  IconButton,
  Typography,
  CardMedia,
  LinearProgress,
  Grid,
  Tooltip,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  getApplicationDetails,
  nonIndividualFormValidForSubmission,
  updateApplication,
} from '../../redux-store/actions/application';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { RejectedDetails } from '../ApplicationDetails';
import {
  AmlDetails,
  CommonLayout,
  ConfirmationDialog,
  DownloadApplication,
} from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  APPLICATION_STATUS,
  USER_ROLES,
  ApplicationStatusBasedOnRole,
  REJECTED_ROLES,
  IndividualApplicationStatusBasedOnRole,
  APPLICATION_LISTING_STATUS,
} from '../../utils/constant';
import { formatShortDateAndTime } from '../../utils/date';
import { checkIfApplicationIsNonIndividual } from '../../utils/utilityFunctions';
import ContributorDetails from './ContributorDetails';
import { RootStateType } from '../../redux-store/reducers';
import ContactDetails from './ContactDetails';
import InvestAndPaymentDetails from '../ApplicationDetails/InvestmentAndPayment';
import BankDetails from '../ApplicationDetails/BankDetails';
import AuthorizedSignatories from './AuthorisedSignatories';
import Documents from './Document';
import Ubo from './ubo';
import Fatca from './fatca';
import { useRouteMatch } from 'react-router';
import DistributorDetails from '../ApplicationDetails/rmDistributor';
import PhysicalSigningDocuments from '../ApplicationDetails/PhysicalSigningDocuments';

export const NonIndividualApplicationLayout = ({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
            }}
            mb={3}>
            <Box sx={{ p: 2 }}>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={2}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}>
                    Application ID: {application.applicationNumber}
                  </Typography>
                  {application.folio_no && (
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}>
                      Folio Number: {application.folio_no}
                    </Typography>
                  )}
                  <Typography
                    className={
                      application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]
                    }
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                      '&.Draft .MuiTypography-root': {
                        color: 'rgba(51, 127, 201, 0.7)',
                      },
                      '&.Completed .MuiTypography-root': {
                        fontWeight: 600,
                        color: 'rgba(35, 133, 63, 0.7)',
                      },
                      '&.Rejected .MuiTypography-root': {
                        color: '#BD2932',
                        fontWeight: 500,
                      },
                    }}>
                    Status:{' '}
                    <Typography
                      component="span"
                      sx={{
                        color: '#B78813',
                        fontSize: '16px',
                        letterSpacing: '0.4px',
                        ml: 0.5,
                      }}>
                      {application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]}
                    </Typography>
                  </Typography>
                  <DownloadApplication application={application} />
                </Grid>
                {application.status === 'rejected' && (
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={1}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <RejectedDetails
                      title={'Rejected by'}
                      description={REJECTED_ROLES[application.rejectedByRole]}
                    />
                    <RejectedDetails
                      title={'Rejected on'}
                      description={formatShortDateAndTime(application.rejectedAt)}
                    />
                    <RejectedDetails
                      title={'Reason for rejection'}
                      description={application.rejectedReason}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          <AmlDetails
            applicants={application.applicants}
            applicationType={application.applicationType}
          />
          <DistributorDetails application={application} />
          <ContributorDetails applicants={application.applicants} />
          <ContactDetails applicants={application.applicants} />
          <InvestAndPaymentDetails application={application} />
          <BankDetails banks={application.banks} />
          <Documents application={application} />

          <AuthorizedSignatories groups={application.groups} />
          <Ubo application={application} />
          <Fatca applicants={application.applicants} />
          {!!application?.signedDocuments?.length && (
            <PhysicalSigningDocuments application={application} />
          )}
        </>
      )}
    </>
  );
};

//Read only view for RM, Distributor, Subdistributor
export const NonIndividualApplicationDetail = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const { path } = useRouteMatch();
  const [openDialog, setDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const _application = (await dispatch(
          getApplicationDetails(id)
        )) as unknown as ApplicationProps;
        if (!isComponentActive) return;
        setApplication(_application);
      } catch (e) {
        console.log(e);
      } finally {
        if (isComponentActive) setLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const { editable } = application || {};

  const handleSubmit = async () => {
    try {
      const { id } = application || {};
      setIsSubmitting(true);
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,

              status: 'sent_to_amc_approver',
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CommonLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to="/applications">
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() => history.push(`/non-individual-application`, { id })}
              />
            </IconButton>
          )}
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <NonIndividualApplicationLayout
          loading={loading}
          application={application as ApplicationProps}
        />
        {path === '/non-individual-application-preview/:id' && editable && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{
                color: 'common.white',
                minWidth: '200px',
                mt: 4,
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: '16px',
              }}
              onClick={() => setDialog(true)}
              disabled={isSubmitting}>
              {`${'Send to AIF Approver'} ${isSubmitting ? '...' : ''}`}
            </Button>
          </Box>
        )}
        <ConfirmationDialog
          message={'Are you sure you want to send it to AIF Approver?'}
          open={openDialog}
          setOpen={() => setDialog(false)}
          onSave={async () => {
            setDialog(false);
            handleSubmit();
          }}
          onCancel={() => setDialog(false)}
        />
      </>
    </CommonLayout>
  );
};
